import React from "react";
import {CampaignAnalytics} from "../../../model/Analytics";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import PasswordIcon from "@mui/icons-material/Password";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {StatCard} from "./StatCard";
import {useTranslation} from "react-i18next";
import {Grid, useTheme} from "@mui/material";
import {ExplanationTooltip} from "./ExplanationTooltip";
import classes from '../../../styling/components/analytics/StatCardPanel.module.css';

interface Props {
    stats: CampaignAnalytics
    reportingEnabled: boolean
    interactionEnabled: boolean
}

export const StatCardPanel: React.FC<Props> = (props) => {
    const {stats, reportingEnabled, interactionEnabled} = props
    const theme = useTheme();

    const {t} = useTranslation();

    const mdCardSize = !reportingEnabled || !interactionEnabled ? 5.8 : 4
    const doughnutSize = !reportingEnabled || !interactionEnabled ? 3 : 4


    const dataClicked = {
        datasets: [{
            data: [stats.clicked, stats.sent - stats.clicked],// @ts-ignore
            backgroundColor: [theme.palette.click.main, "#F0F0F0"]
        }],
        labels: [t('statCard.clicked'), t('statCard.notClicked')]
    }

    const dataReported = {
        datasets: [{
            data: [stats.reported, stats.sent - stats.reported],// @ts-ignore
            backgroundColor: [theme.palette.reporting.main, "#F0F0F0"]
        }],
        labels: [t('statCard.reported'), t('statCard.notReported')]
    }

    const dataInteracted = {
        datasets: [{
            data: [stats.interactedCritical, stats.interacted, stats.interactableClicked - stats.interactedCritical - stats.interacted],// @ts-ignore
            backgroundColor: [theme.palette.interaction.main, "#FFE3C4", "#F0F0F0"]
        }],
        labels: [t('statCard.criticalInteraction'), t('statCard.nonCriticalInteraction'), t('statCard.noInteraction')]
    }


    const clickTitle = <div className={classes.title}>
        {t("campaignAnalyticsView.clickedCardTitle")}
        <ExplanationTooltip i18nKey={"explanations.clickrate"} title={t("explanations.clickrateTitle")}/>
    </div>

    const reportingTitle = <div className={classes.title}>
        {t("campaignAnalyticsView.reportedCardTitle")}
        <ExplanationTooltip i18nKey={"explanations.reportingrate"} title={t("explanations.reportingrateTitle")}/>
    </div>

    const interactionTitle = <div className={classes.title}>
        {t("campaignAnalyticsView.interactedCardTitle")}
        <ExplanationTooltip i18nKey={"explanations.interactionrate"} title={t("explanations.interactionrateTitle")}
                            placement={"left"}/>
    </div>

    return (
        <div className={classes.statCardPanel}>
            <Grid container columnSpacing={3} rowSpacing={2} justifyContent={"space-between"}>
                <Grid item xs={12} md={mdCardSize}>
                <div>
                    <StatCard headline={clickTitle} // @ts-ignore
                              icon={<AdsClickIcon color={"click"}/>} divisor={stats.clicked} dividend={stats.sent}
                              data={dataClicked}
                              doughnutSize={doughnutSize}
                    />
                </div>
            </Grid>
                {reportingEnabled ?
                    <Grid item xs={12} md={mdCardSize}>
                        <div>
                            <StatCard headline={reportingTitle} // @ts-ignore
                                      icon={<NotificationsActiveIcon color={"reporting"}/>}
                                      divisor={stats.reported} dividend={stats.sent}
                                      data={dataReported}
                                      doughnutSize={doughnutSize}
                            />
                        </div>
                    </Grid>
                    : null}
                {interactionEnabled ?
                    <Grid item xs={12} md={mdCardSize}>
                        <div>
                            <StatCard headline={interactionTitle} // @ts-ignore
                                      icon={<PasswordIcon color={"interaction"}/>}
                                      divisor={stats.interacted + stats.interactedCritical}
                                      dividend={stats.interactableClicked}
                                      data={dataInteracted}
                                      doughnutSize={doughnutSize}
                            />
                        </div>
                    </Grid>
                    : null}

        </Grid>
        </div>
    )
}