import {fetchWithToken} from "./fetchWithToken";

const getBaseUrl = () => {
  let url;
  switch(process.env.NODE_ENV) {
    case 'production':
        url = '/api/system/dispatches';
      break;
    case 'development':
        url = 'http://localhost:5000/api/system/dispatches';
     break;
    default:
        url = '/api/system/dispatches';
  }

  return url;
}

export async function getCampaignDispatches(campaign_id: string){
    try {
        const response = await fetchWithToken(getBaseUrl() + `/campaign=${campaign_id}`, {
            method: "GET",
        })
        return response
    } catch (error){
        throw new Error("Error")
    }
}
