import React, {useState} from "react";
import {Button, Card, CardContent, CardHeader, Collapse, ToggleButton, ToggleButtonGroup} from "@mui/material";
import classes from '../../../styling/components/analytics/TemplateCard.module.css';
import {ClickRateTable} from "./ClickRateTable";
import {InteractionRateTable} from "./InteractionRateTable";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import PasswordIcon from "@mui/icons-material/Password";
import {useTranslation} from "react-i18next";
import {ExplanationTooltip} from "./ExplanationTooltip";

interface Props {
    clickStats: {
        name: string,
        templateId: string,
        sent: number,
        clicked: number,
    }[],
    interactionStats?: {
        name: string,
        templateId: string,
        interacted: number,
        interactedCritical: number,
        interactableClicked: number
    }[],
    showInteractionRate?: boolean
}

export const TemplateCard: React.FC<Props> = (props) => {
    const {clickStats, interactionStats, showInteractionRate} = props

    const {t} = useTranslation()

    const [showClickrate, setShowClickrate] = useState(true)
    const [showAll, setShowAll] = useState(false)
    const interactionRateToggleable = showInteractionRate && !!interactionStats && interactionStats.length > 0

    const renderShowAllButton = (showClickrate && clickStats.length > 3) || (!showClickrate && (interactionStats?.length ?? 0) > 3)
    const handleShowAll = () => {
        setShowAll((prev) => (!prev));
    }

    const cardHeaderElement = showInteractionRate ? <ToggleButtonGroup
        value={showClickrate}
        exclusive
        onChange={(event, newValue) => {
            if (newValue !== null)
                setShowClickrate(newValue)
        }}

    >
        {/* @ts-ignore*/}
        <ToggleButton value={true} color={"click"}>
            <AdsClickIcon/>
        </ToggleButton>
        {/* @ts-ignore*/}
        <ToggleButton value={false} color={"interaction"}>
            <PasswordIcon/>
        </ToggleButton>
    </ToggleButtonGroup> : null

    const title = <div className={classes.title}>
        E-Mails
        <ExplanationTooltip i18nKey={"explanations.templateCard"} title={t("explanations.templateCardTitle")}/>
    </div>

    return (
        clickStats.length > 0 || (interactionStats?.length ?? 0) > 0 ?
            <Card className={classes.templateCard}>
                <CardHeader title={title} action={cardHeaderElement}/>
                <CardContent>
                    {/*TODO Collapsed size responsive? */}
                    <Collapse in={showAll} collapsedSize={250}>
                        {!showClickrate && interactionRateToggleable ?
                            <InteractionRateTable stats={interactionStats} cutOff={showAll ? undefined : 3}/> :
                            <ClickRateTable stats={clickStats} cutOff={showAll ? undefined : 3}/>
                        }
                    </Collapse>
                    {renderShowAllButton ?
                        <Button onClick={handleShowAll} className={classes.button}>
                            {t(showAll ? "templateCard.showLess" : "templateCard.showAll")}
                        </Button> : null
                    }

                </CardContent>
            </Card> : null
    )
}