import React from "react";
import classes from "../../../styling/components/analytics/TrendStatCard.module.css";
import {toPct} from "../../../util/Calc";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {ExplanationTooltip} from "./ExplanationTooltip";
import {useTranslation} from "react-i18next";

interface Props {
    headline: string,
    i18nKeyTooltip: string,
    divisor: number,
    dividend: number,
    delta: number,
    invertedTrend: boolean //True if positive delta implies a negative trend
    color: "clickrate" | "reportingrate"
}

export const TrendStatCard: React.FC<Props> = (props) => {
    const {headline, i18nKeyTooltip, divisor, dividend, delta, invertedTrend, color} = props

    const {t} = useTranslation()
    const rate = toPct(divisor, dividend)
    const stylingClass = color === "clickrate" ? classes.clickrate : classes.reportingrate

    const negativeTrend = invertedTrend ? delta > 0 : delta < 0
    const readableDelta = Intl.NumberFormat("de-DE", {
        signDisplay: "always",
    }).format(delta)

    const icon = delta >= 0 ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>

    return (
        <div className={classes.trendStatCard + " " + stylingClass}>
            <div className={classes.titleArea}>{headline} <ExplanationTooltip title={t(i18nKeyTooltip + "Title")}
                                                                              i18nKey={i18nKeyTooltip}
                                                                              placement={"top"}/></div>
            <div className={classes.rateDeltaContainer}>
                <div className={classes.rate}>{rate} %</div>
                <div className={`${classes.delta} ${negativeTrend ? classes.negative : classes.positive}`}>{icon}
                    <span>{readableDelta}%</span></div>
            </div>

            <div className={classes.amounts}>{divisor} / {dividend}</div>
        </div>
    )
}