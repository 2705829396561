import React, {Suspense} from "react";
import {Await, defer, json, useLoaderData} from "react-router-dom";
import {Template} from "../../model/Template";
import {CircularProgress} from "@mui/material";
import {getCustomerTemplates, listDemoTemplates} from "../../api/templateClient";
import {DemoConfigEditor} from "../../components/Customer/DemoConfigEditor";

const DemoConfigPage: React.FC = (props) => {
    const {templates, demoTemplates} = useLoaderData() as {
        templates: Template[],
        demoTemplates: { templateId: string, name: string }[]
    };

    return (
        <Suspense fallback={<CircularProgress/>}>
            <Await resolve={Promise.all([templates, demoTemplates])}>
                {data => <DemoConfigEditor templates={data[0]} demoTemplateIds={data[1].map((t: {
                    templateId: string,
                    name: string
                }) => t.templateId)}/>}
            </Await>
        </Suspense>
    )
}

export default DemoConfigPage;

export async function loadTemplates(customerId: string) {
    const response = await getCustomerTemplates(customerId);

    if (!response.ok) {
        throw json({message: 'Could not fetch template.'}, {status: 500});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loadDemoTemplates(customerId: string) {
    const response = await listDemoTemplates(customerId);
    if (!response.ok) {
        throw json({message: 'Could not fetch templateTags.'}, {status: response.status});
    } else {
        const resData = await response.json();
        return resData
    }
}

export async function loader({
                                 request,
                                 params
                             }: { request: Request, params: { customerId: string } }) {
    const customerId = params.customerId;

    return defer({
        templates: loadTemplates(customerId),
        demoTemplates: loadDemoTemplates(customerId),
    })
}