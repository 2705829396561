import {isRouteErrorResponse, NavLink, useNavigate, useRouteError} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";
import classes from '../../styling/components/ErrorPage.module.css'
import {Button} from "@mui/material";

const ErrorPage: React.FC = (props) => {
    const { t } = useTranslation();
    const error = useRouteError();
    const navigate = useNavigate();

    let title = `${t('errorPage.defaultTitle')}`;
    let message = `${t('errorPage.defaultMessage')}`;

    if (isRouteErrorResponse(error)) {
        if (error.status === 500) {
            message = error.data.message;
        }

        if (error.status === 404) {
            title = `${t('errorPage.notFoundTitle')}`;;
            message = `${t('errorPage.notFoundMessage')}`;;
        }
    }

    const handleBackToHome = () => {
        navigate("/")
    }
    return (
        <>
            <div className={classes.errorPage}>
                <div className={classes.logo}>
                    <NavLink to={"/"}><img src={"/assets/img/look2x-logo-farbig.svg"}/> </NavLink>
                </div>

                <div className={classes.errorimg}>
                    <img src={"/assets/img/error-icon.png"}/>
                </div>
                <div className={classes.errortext}>
                    <h1>{title}</h1>
                    <p>{message}</p>
                </div>
                <div className={classes.button}>
                    <Button onClick={handleBackToHome}>{t('errorPage.backToHome')}</Button>
                </div>

            </div>
        </>
    );
}

export default ErrorPage;