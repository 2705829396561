import './App.css';
import './LoginPage.css';
import {loginPageTranslations} from './loginPageTranslations';
import React, {createContext, useEffect, useState} from 'react';

import {createBrowserRouter, RouterProvider} from "react-router-dom";
import CustomerPage, {loader as customersLoader} from './pages/Customer/CustomerManager';
import NewTemplatePage, {loader as templateTagLoader} from './pages/Template/NewTemplate';
import {action as addOrEditTemplateAction} from './components/Template/TemplateEditor';
import {action as addOrEditCustomerAction} from './components/Customer/CustomerEditor';
import {action as addOrEditReceiverGroupAction} from './components/ReceiverGroup/ReceiverGroupEditor';
import {action as addOrEditReceiverAction} from './components/Receiver/ReceiverEditor';
import {action as addOrEditTestmailConfigAction} from './components/Testmail/TestmailConfigEditor';
import TemplateManagerPage, {loader as customerTemplatesAndCampaignsLoader} from './pages/Template/TemplateManager';
import EditTemplatePage, {loader as templateFromCustomerLoader} from './pages/Template/EditTemplate';
import NewCustomerPage from './pages/Customer/NewCustomer';
import EditCustomerPage, {loader as customerInfoLoader} from './pages/Customer/EditCustomer';
import ReceiverManagerPage, {loader as customerReceiversLoader} from './pages/Receiver/ReceiverManager';
import NewReceiverPage, {loader as customerReceiverGroupsLoader} from './pages/Receiver/NewReceiver';
import EditReceiverPage, {loader as customerEditReceiverLoader} from './pages/Receiver/EditReceiver';
import CampaignManagerPage, {loader as customerCampaignsLoader} from './pages/Campaign/CampaignManager';
import NewCampaignPage, {loader as newCampaignLoader} from './pages/Campaign/NewCampaign';
import EditCampaignPage, {loader as customerCampaignLoader} from './pages/Campaign/EditCampaign';
import CampaignAnalyticsPage, {loader as campaignAnalyticsLoader} from "./pages/Analytics/CampaignAnalytics";
import {action as addOrEditCampaignAction} from './components/Campaign/CampaignEditor';
import NewReceiverGroupPage, {loader as addOrEditReceiverGroupLoader} from './pages/ReceiverGroup/NewReceiverGroup';
import RootLayout from './components/Navigation/RootLayout';
import ErrorPage from './components/Navigation/ErrorPage';
import CustomerLayout from "./components/Navigation/CustomerLayout";
import {withAuthenticator, WithAuthenticatorProps} from '@aws-amplify/ui-react';
import {Hub, I18n} from "aws-amplify";
import DispatchManagerPage, {loader as campaignDispatchesLoader} from './pages/Dispatch/DispatchManager';
import TemplatePreviewPage, {loader as previewLoader} from './pages/Template/TemplatePreview';
import TestmailConfigPage, {loader as testmailConfigLoader} from './pages/Testmail/TestmailConfig';
import AnalyticsOverview, {loader as analyticsOverviewLoader} from "./pages/Analytics/AnalyticsOverview";
import TemplateTagManagerPage, {loader as templateTagGroupsLoader} from './pages/TemplateTag/TemplateTagManager';
import NewTemplateTagPage from './pages/TemplateTag/NewTemplateTag';
import EditTemplateTagPage, {loader as editTemplateTagLoader} from './pages/TemplateTag/EditTemplateTag';
import {action as addOrEditTemplateTagAction} from './components/TemplateTag/TemplateTagEditor';
import EditReceiverGroupPage from './pages/ReceiverGroup/EditReceiverGroup';
import DomainManagerPage, {loader as domainGroupsLoader} from './pages/Domain/DomainManager';
import NewDomainPage from './pages/Domain/NewDomain';
import EditDomainPage, {loader as editDomainLoader} from './pages/Domain/EditDomain';
import {action as addOrEditDomainAction} from './components/Domain/DomainEditor';
import WhitelistingInfoPage, {loader as campaignDomainsLoader} from './pages/Campaign/WhitelistingInfo';
import EditCustomerUserPage, {editCustomerUserPageLoader} from "./pages/User/EditCustomerUser";
import NewCustomerUserPage, {newCustomerUserPageLoader} from "./pages/User/NewCustomerUser";
import {action as addOrEditUserAction} from './components/User/UserEditor';
import {action as editSettingsAction} from './components/User/SettingsEditor'
import EditInstanceUserPage, {editInstanceUserPageLoader} from "./pages/User/EditInstanceUser";
import NewInstanceUserPage, {newInstanceUserPageLoader} from "./pages/User/NewInstanceUser";
import EditSystemUserPage, {editSystemUserPageLoader} from "./pages/User/EditSystemUser";
import NewSystemUserPage, {newSystemUserPageLoader} from "./pages/User/NewSystemUser";
import {MonitoringOverview} from "./components/Monitoring/MonitoringOverview";
import {OrganizationLevel, SelfUser} from "./model/SelfUser";
import {UserForwarder} from "./components/Routing/UserForwarder";
import {CircularProgress} from "@mui/material";
import {getSelfUser} from "./api/userClient";
import {getAuthHeader} from "./api/fetchWithToken";
import {useTranslation} from "react-i18next";
import EditSettingsPage, {editSettingsPageLoader} from "./pages/User/EditSettings";
import {LoginPageHeader} from "./components/LoginPage/LoginPageHeader";
import DemoAnalyticsOverviewPage from "./pages/Analytics/DemoAnalyticsOverview";
import DemoCampaignAnalyticsPage, {
    loader as DemoCampaignAnalyticsLoader
} from "./pages/Analytics/DemoCampaignAnalytics";
import DemoConfigPage, {loader as demoConfigLoader} from "./pages/Customer/DemoConfig";
import {action as updateDemoConfigAction} from "./components/Customer/DemoConfigEditor"

const emptyUser: SelfUser = {
    userId: "",
    organizationLevel: OrganizationLevel.CUSTOMER,
    organizationId: "X",
    firstName: "",
    lastName: "",
    language: "de",
    role: ""
}

export const UserContext = createContext({
    user: emptyUser,
    setUser: (user: SelfUser) => {
    },
    signOut: () => {
    },
    authHeader: ""
})

function App({signOut}: WithAuthenticatorProps) {

    const [user, setUser] = useState(emptyUser)
    const [authHeader, setAuthHeader] = useState("")
    const contextValue = {user, setUser, signOut, authHeader}
    const {i18n} = useTranslation()

    useEffect(() => {
        getSelfUser()
            .then(response => {
                if (!response.ok)
                    throw new Error()
                else return response.json()
            })
            .then((user: SelfUser) => {
                setUser(user)
                if (user.language)
                    i18n.changeLanguage(user.language)
            })
    }, []);

    useEffect(() => {
        getAuthHeader().then(header => setAuthHeader(header))
    }, [])

    const authListener = (data: any) => {
        if (data?.payload?.event === 'signOut')
            window.location.href = '/'
    }

    Hub.listen('auth', authListener)


    const router = createBrowserRouter([
        {
            path: "customers/:customerId/templates/:templateId/preview",
            element: <TemplatePreviewPage/>,
            errorElement: <ErrorPage />,
            loader: previewLoader as any
        },
        {
            path: "/",
            element: <RootLayout />,
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    element: <UserForwarder/>
                },
                {
                    path: "customers",
                    element: <CustomerPage />,
                    loader: customersLoader,
                },
                {
                    path: "customers/new",
                    element: <NewCustomerPage />,
                    action: addOrEditCustomerAction,
                },
                {
                    element: <CustomerLayout />,
                    children: [
                        {
                            path: "customers/:customerId",
                            children: [
                                {
                                    path: "details",
                                    element: <EditCustomerPage/>,
                                    action: addOrEditCustomerAction,
                                    loader: customerInfoLoader as any,
                                },
                                {
                                    path: "testmailConfig",
                                    element: <TestmailConfigPage/>,
                                    action: addOrEditTestmailConfigAction,
                                    loader: testmailConfigLoader as any,
                                },
                                {
                                    path: "democonfig",
                                    element: <DemoConfigPage/>,
                                    action: updateDemoConfigAction,
                                    loader: demoConfigLoader

                                },
                                {
                                    path: "templates",
                                    children: [
                                        {
                                            index: true,
                                            element: <TemplateManagerPage />,
                                            loader: customerTemplatesAndCampaignsLoader as any,
                                        },
                                        {
                                            path: "new",
                                            element: <NewTemplatePage />,
                                            action: addOrEditTemplateAction,
                                            loader: templateTagLoader as any,
                                        },
                                        {
                                            path: ":templateId",
                                            element: <EditTemplatePage/>,
                                            action: addOrEditTemplateAction,
                                            loader: templateFromCustomerLoader as any,
                                            /*children: [
                                                {
                                                    path: "preview",
                                                    element: <TemplatePreviewPage />,
                                                    loader: templateFromCustomerLoader as any

                                                }
                                            ]*/
                                        },
                                    ]
                                },
                                {
                                    path: "receivers",
                                    children: [
                                        {
                                            index: true,
                                            id: 'customer-receivers',
                                            loader: customerReceiversLoader as any,
                                            element: <ReceiverManagerPage />,
                                        },
                                        {
                                            path: "new-receivergroup",
                                            element: <NewReceiverGroupPage/>,
                                            loader: addOrEditReceiverGroupLoader as any,
                                            action: addOrEditReceiverGroupAction,
                                        },
                                        {
                                            path: "edit-receivergroup/:receiverGroupId",
                                            element: <EditReceiverGroupPage/>,
                                            loader: addOrEditReceiverGroupLoader as any,
                                            action: addOrEditReceiverGroupAction,
                                        },
                                        {
                                            path: "new-receiver",
                                            element: <NewReceiverPage />,
                                            loader: customerReceiverGroupsLoader as any,
                                            action: addOrEditReceiverAction,
                                        },
                                        {
                                            path: ":receiverId",
                                            element: <EditReceiverPage/>,
                                            loader: customerEditReceiverLoader as any,
                                            action: addOrEditReceiverAction,
                                        },
                                    ]
                                },
                                {
                                    path: "campaigns",
                                    children: [
                                        {
                                            index: true,
                                            loader: customerCampaignsLoader as any,
                                            element: <CampaignManagerPage />,
                                        },
                                        {
                                            path: "new",
                                            element: <NewCampaignPage />,
                                            loader: newCampaignLoader as any,
                                            action: addOrEditCampaignAction as any,
                                        },
                                        {
                                            path: ":campaignId",
                                            element: <EditCampaignPage />,
                                            loader: customerCampaignLoader,
                                            action: addOrEditCampaignAction,
                                        },
                                        {
                                            path: ":campaignId/dispatches",
                                            element: <DispatchManagerPage/>,
                                            loader: campaignDispatchesLoader as any,
                                        },
                                        {
                                            path: ":campaignId/whitelisting",
                                            element: <WhitelistingInfoPage />,
                                            loader: campaignDomainsLoader as any,
                                        },
                                    ]
                                },
                                {
                                    path: "domains",
                                    children: [
                                        {
                                            index: true,
                                            element: <DomainManagerPage/>,
                                            loader: domainGroupsLoader as any,
                                        },
                                        {
                                            path: "new",
                                            element: <NewDomainPage/>,
                                            loader: domainGroupsLoader as any,
                                            action: addOrEditDomainAction,
                                        },
                                        {
                                            path: ":domainId",
                                            element: <EditDomainPage/>,
                                            loader: editDomainLoader as any,
                                            action: addOrEditDomainAction,
                                        },
                                    ]
                                },
                                {
                                    path: "templatetags",
                                    children: [
                                        {
                                            index: true,
                                            element: <TemplateTagManagerPage/>,
                                            loader: templateTagGroupsLoader as any,
                                        },
                                        {
                                            path: "new",
                                            element: <NewTemplateTagPage/>,
                                            loader: templateTagGroupsLoader as any,
                                            action: addOrEditTemplateTagAction,
                                        },
                                        {
                                            path: ":templateTagId",
                                            element: <EditTemplateTagPage/>,
                                            loader: editTemplateTagLoader as any,
                                            action: addOrEditTemplateTagAction,
                                        },
                                    ]
                                },
                                {
                                    path: "dispatches",
                                    children: [
                                        {
                                            path: ":campaignId",
                                            element: <DispatchManagerPage/>,
                                            loader: campaignDispatchesLoader as any,
                                        },
                                    ]
                                },
                                {
                                    path: "demo/analytics",
                                    children: [
                                        {
                                            index: true,
                                            element: <DemoAnalyticsOverviewPage/>,
                                            loader: undefined
                                        },
                                        {
                                            path: ":campaignId",
                                            element: <DemoCampaignAnalyticsPage/>,
                                            loader: DemoCampaignAnalyticsLoader
                                        }
                                    ]
                                },
                                {
                                    path: "analytics",
                                    children: [
                                        {
                                            index: true,
                                            element: <AnalyticsOverview/>,
                                            loader: analyticsOverviewLoader
                                        },
                                        {
                                            path: ":campaignId",
                                            element: <CampaignAnalyticsPage/>,
                                            loader: campaignAnalyticsLoader
                                        }
                                    ]
                                },
                                {
                                    path: "users",
                                    children: [
                                        {
                                            path: ":userId",
                                            element: <EditCustomerUserPage/>,
                                            loader: editCustomerUserPageLoader,
                                            action: addOrEditUserAction
                                        },
                                        {
                                            path: "new",
                                            element: <NewCustomerUserPage/>,
                                            loader: newCustomerUserPageLoader,
                                            action: addOrEditUserAction
                                        }
                                    ]
                                },
                                {
                                    path: "settings",
                                    element: <EditSettingsPage/>,
                                    loader: editSettingsPageLoader,
                                    action: editSettingsAction
                                }
                            ]
                        },
                    ]
                },
                {
                    path: "instance/:instanceId/users",
                    children: [
                        {
                            path: ":userId",
                            element: <EditInstanceUserPage/>,
                            loader: editInstanceUserPageLoader,
                            action: addOrEditUserAction
                        },
                        {
                            path: "new",
                            element: <NewInstanceUserPage/>,
                            loader: newInstanceUserPageLoader as any,
                            action: addOrEditUserAction
                        },
                        {
                            path: "settings",
                            element: <EditSettingsPage/>,
                            loader: editSettingsPageLoader,
                            action: editSettingsAction
                        }
                    ]
                },
                {
                    path: "system/users",
                    children: [
                        {
                            path: ":userId",
                            element: <EditSystemUserPage/>,
                            loader: editSystemUserPageLoader,
                            action: addOrEditUserAction
                        },
                        {
                            path: "new",
                            element: <NewSystemUserPage/>,
                            loader: newSystemUserPageLoader as any,
                            action: addOrEditUserAction
                        },
                        {
                            path: "settings",
                            element: <EditSettingsPage/>,
                            loader: editSettingsPageLoader,
                            action: editSettingsAction
                        }
                    ]
                },
                {
                    path: "monitoring",
                    element: <MonitoringOverview/>
                }
            ]
        },
    ])

    return (
        // @ts-ignore
        <UserContext.Provider value={contextValue}>
            {user === emptyUser ? <CircularProgress/> :
                <div className="App">
                    <RouterProvider router={router}/>
                </div>}
        </UserContext.Provider>

    );
}

I18n.putVocabularies(loginPageTranslations);

export default withAuthenticator(App,
    {
        hideSignUp: true,
        components: {
            ResetPassword: {Header: () => <LoginPageHeader title={"Passwort zurücksetzen"}/>},
            ConfirmResetPassword: {Header: () => <LoginPageHeader title={"Passwort zurücksetzen"}/>}
        }
    });