import React from "react";
import HelpIcon from '@mui/icons-material/Help';
import {Tooltip} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import classes from "../../../styling/components/analytics/ExplanationTooltip.module.css";


interface Props {
    title: string
    i18nKey: string
    placement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
}

export const ExplanationTooltip: React.FC<Props> = (props) => {

    const {t} = useTranslation()

    const content = <div className={classes.container}>
        <div className={classes.titleArea}>
            <h3>{props.title}</h3>
        </div>
        <div className={classes.textArea}>
            <Trans i18nKey={props.i18nKey} components={{1: <br/>}}/>
        </div>
    </div>

    return (
        <>
            <Tooltip placement={props.placement ?? "right"}
                     title={content}
            >
                <HelpIcon fontSize={"inherit"} style={{marginLeft: "5px", verticalAlign: "middle"}} color={"primary"}/>
            </Tooltip>

        </>
    )
}