import React, {useState} from "react";
import {Template} from "../../model/Template";
import TemplateTable from "../Template/TemplateTable";
import {Button, Card} from "@mui/material";
import {Form, json, redirect, useSubmit} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {updateDemoTemplates} from "../../api/templateClient";

interface Props {
    templates: Template[],
    demoTemplateIds: string[]
}

export const DemoConfigEditor: React.FC<Props> = (props) => {

    const [selectedTemplateIds, setSelectedTemplateIds] = useState(props.demoTemplateIds)

    const submit = useSubmit()
    const {t} = useTranslation()

    const handleSelectionChange = (newSelection: string[]) => {
        setSelectedTemplateIds(newSelection)
    }

    return (
        <>
            <Card>
                <Form method={"put"}>
                    <TemplateTable templates={props.templates} selectedTemplateIds={selectedTemplateIds}
                                   onSelectionChange={handleSelectionChange}/>
                    {selectedTemplateIds.map((id, index) => {
                        return <input
                            key={index}
                            type="hidden"
                            name="selectedTemplate"
                            value={id}
                        />
                    })}
                    <Button type="submit" variant="contained">
                        {t('customerEditor.save')}
                    </Button>
                </Form>
            </Card>
        </>
    )
}

export async function action({request, params}: { request: Request, params: any }) {
    const data = await request.formData();
    const templateIds = data.getAll('selectedTemplate') as string[]

    const response = await updateDemoTemplates(templateIds, params.customerId)

    if (!response.ok) {
        throw json({message: 'Could not update demo templates'}, {status: response.status});
    } else {
        return redirect('..');
    }
}